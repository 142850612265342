import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable } from "rxjs";

import {
  IntegrationInfo,
  IntegrationStepInfo,
} from "../api-models/integration.models";
import { ApiService } from "./api.service";

@Injectable({
  providedIn: "root",
})
export class IntegrationService extends ApiService {
  intgBase = `${this.endpoint}/integrations/v2`;

  getIntegrations(agency: boolean): Observable<IntegrationInfo[]> {
    const p = new HttpParams({ fromObject: { agency: `${agency}` } });
    return this.http.get<IntegrationInfo[]>(`${this.intgBase}`, { params: p });
  }

  startIntegrationAuth(
    agency: boolean,
    integrationKey: string,
    redirectKey: "/onboarding" | null = null,
  ): Observable<IntegrationStepInfo> {
    let p = new HttpParams().append("agency", `${agency}`);

    if (redirectKey != null) {
      p = p.append("redirect_key", redirectKey);
    }

    return this.http.get<IntegrationStepInfo>(
      `${this.intgBase}/${integrationKey}/auth`,
      { params: p },
    );
  }

  finalizeIntegrationAuth(
    agency: boolean,
    integrationKey: string,
    payload: any,
    redirectKey: "/onboarding" | null = null,
  ): Observable<{ success: boolean; data?: any }> {
    let p = new HttpParams().append("agency", `${agency}`);

    if (redirectKey != null) {
      p = p.append("redirect_key", redirectKey);
    }

    return this.http.post<{ success: boolean; data?: Record<string, any> }>(
      `${this.intgBase}/${integrationKey}/auth`,
      payload,
      { params: p },
    );
  }

  startIntegrationSetup(
    agency: boolean,
    integrationKey: string,
  ): Observable<IntegrationStepInfo> {
    return this.http.get<IntegrationStepInfo>(
      `${this.intgBase}/${integrationKey}/setup?agency=${agency}`,
    );
  }

  finalizeIntegrationSetup(
    agency: boolean,
    integrationKey: string,
    payload: any,
  ): Observable<{ success: boolean; data?: any }> {
    return this.http.post<{ success: boolean; data?: Record<string, any> }>(
      `${this.intgBase}/${integrationKey}/setup?agency=${agency}`,
      payload,
    );
  }
}

import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

import { Subject } from "rxjs";

import { AccessService } from "./access.service";

export interface AuthStorage {
  access_token: string;
}

@Injectable({
  providedIn: "root",
})
export class CAuthService {
  AUTH_STORAGE_KEY = "auth_bearer";

  private authChangeSubject = new Subject<string | undefined>();
  onAuthChange$ = this.authChangeSubject.asObservable();

  constructor(
    private router: Router,
    private accessService: AccessService,
  ) {
    const oldAuthRaw = localStorage.getItem("auth_app_token");
    if (oldAuthRaw) {
      const oldAuth = JSON.parse(oldAuthRaw);

      // Migrate old nb auth
      const t: string | undefined = oldAuth["value"];
      if (t) {
        this.registerAuthToken(t);
      }
      localStorage.removeItem("auth_app_token");
    }
  }

  registerAuthToken(token: string | null) {
    if (token) {
      const s: AuthStorage = {
        access_token: token,
      };
      localStorage.setItem(this.AUTH_STORAGE_KEY, JSON.stringify(s));
    } else {
      localStorage.removeItem(this.AUTH_STORAGE_KEY);
    }

    this.authChangeSubject.next(token);
  }

  private authInfo(): AuthStorage | undefined {
    const r = localStorage.getItem(this.AUTH_STORAGE_KEY);
    if (!r) {
      return undefined;
    }
    return JSON.parse(r) as AuthStorage;
  }

  token(): string | undefined {
    return this.authInfo()?.access_token;
  }

  isAuthenticated(): boolean {
    return this.token() != null;
  }

  logout() {
    this.accessService.unselectAccess();

    localStorage.clear();
    sessionStorage.clear();

    this.authChangeSubject.next(this.token());

    setTimeout(() => {
      this.router.navigate(["/auth/login"]);
    });
  }
}

import { Injectable } from "@angular/core";

import { Me } from "@core/api-models/users.models";

import Hotjar from "@hotjar/browser";
import { environment } from "environments/environment";

import { AccessSelection } from "./access.service";

@Injectable({
  providedIn: "root",
})
export class HotjarService {
  private initialized = false;

  constructor() {}

  init() {
    if (!environment.hotjar) {
      return;
    }

    if (this.initialized) {
      return;
    }

    Object.defineProperty(window, "_hjSettings", {
      value: { hjid: environment.hotjar, hjsv: 6 },
      configurable: true,
      writable: true,
    });

    Hotjar.init(environment.hotjar, 6, {
      debug: environment.production === false,
    });

    this.initialized = true;
  }

  private prefixInfo(prefix: string, infoObj: Record<string, any>) {
    const info = {};
    for (const [k, v] of Object.entries(infoObj)) {
      info[`${prefix}_${k}`] = v;
    }
    return info;
  }

  identify(user: Me, access: AccessSelection) {
    if (!this.initialized) {
      return;
    }
    return Hotjar.identify(`${user.id}`, {
      ...this.prefixInfo("company_info", access.company.info),
      ...this.prefixInfo("user_info", user.info),
      email: user.email,
      companyId: access.company.id,
      accountId: access.account?.id,
    });
  }

  event(name: string) {
    if (!this.initialized) {
      return;
    }
    return Hotjar.event(name);
  }
}

import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { ImageSettings, User } from "@core/api-models/admin.types";

import { Observable } from "rxjs";

import {
  AccountFeatureInfo,
  AccountSettings,
  AccountSettingsUpdate,
  AccountSubscription,
  CompanyPlanState,
  ExpansionRequest,
  OnboardingCatalogData,
  OnboardingStatus,
  RegionTimezones,
  SubscriptionInfo,
} from "../api-models/settings.models";
import { ApiService } from "./api.service";

@Injectable({
  providedIn: "root",
})
export class SettingsService extends ApiService {
  getCompany(): Observable<any> {
    return this.http.get(this.endpoint + "/settings/company");
  }

  updateCompany(company): Observable<any> {
    return this.http.post(this.endpoint + "/settings/company", company);
  }

  getAccount(): Observable<AccountSettings> {
    return this.http.get<AccountSettings>(this.endpoint + "/settings/account");
  }

  getAccountOnboardingStatus(): Observable<OnboardingStatus> {
    return this.http.get<OnboardingStatus>(
      this.endpoint + "/settings/account/onboarding",
    );
  }

  getAccountOnboardingCatalogs(): Observable<OnboardingCatalogData> {
    return this.http.get<OnboardingCatalogData>(
      this.endpoint + "/settings/account/onboarding/catalogs",
    );
  }

  getAccountImageSettings(): Observable<ImageSettings> {
    return this.http.get<ImageSettings>(
      `${this.endpoint}/settings/account/image-settings`,
    );
  }

  updateAccountImageSettings(
    settings: ImageSettings,
  ): Observable<{ success: boolean }> {
    return this.http.post<{ success: boolean }>(
      this.endpoint + "/settings/account/image-settings",
      settings,
    );
  }

  finalizeAccountOnboarding(): Observable<{ success: boolean }> {
    return this.http.post<{ success: boolean }>(
      this.endpoint + "/settings/account/finalize-onboarding",
      null,
    );
  }

  updateAccount(
    update: AccountSettingsUpdate,
  ): Observable<{ success: boolean }> {
    return this.http.patch<{ success: boolean }>(
      this.endpoint + "/settings/account",
      update,
    );
  }

  getTimezones(): Observable<RegionTimezones[]> {
    return this.http.get<RegionTimezones[]>(this.endpoint + "/meta/timezones");
  }

  // Card/subscription endpoints
  getCurrentCard(): Observable<any> {
    return this.http.get(`${this.endpoint}/settings/billing/current-card`);
  }

  getCardIntent(): Observable<any> {
    `${this.endpoint}/settings/billing/card-setup-intent`;

    return this.http.post(
      `${this.endpoint}/settings/billing/card-setup-intent`,
      null,
    );
  }

  completeCardIntent(intent): Observable<any> {
    return this.http.post(
      `${this.endpoint}/settings/billing/card-setup-intent-complete`,
      intent,
    );
  }

  getStripeInvoices(): Observable<any> {
    return this.http.get(`${this.endpoint}/settings/billing/invoices`);
  }

  getUsers(): Observable<User[]> {
    return this.http.get<User[]>(`${this.endpoint}/settings/company/users`);
  }

  inviteUser(email: string): Observable<User> {
    return this.http.post<User>(`${this.endpoint}/settings/company/users`, {
      email: email,
    });
  }

  removeUserAccessToCompany(userId) {
    return this.http.delete(
      `${this.endpoint}/settings/company/users/${userId}`,
    );
  }

  getCompanySubscription(): Observable<AccountSubscription[]> {
    return this.http.get<AccountSubscription[]>(
      `${this.endpoint}/settings/company/subscription`,
    );
  }

  requestCancellation(acceptedOffer: boolean, text: string) {
    return this.http.post(`${this.endpoint}/settings/company/cancel_request`, {
      accepted_offer: acceptedOffer,
      text: text,
    });
  }

  requestExpansion(
    request: ExpansionRequest,
  ): Observable<{ success: boolean }> {
    return this.http.post<{ success: boolean }>(
      `${this.endpoint}/settings/request-expansion`,
      request,
    );
    //
  }

  // Account features
  getAccountFeatures(accountID: number): Observable<AccountFeatureInfo[]> {
    return this.http.get<AccountFeatureInfo[]>(
      `${this.endpoint}/settings/account/features`,
      { params: new HttpParams({ fromObject: { account_id: accountID } }) },
    );
  }

  activateAccountFeature(
    accountID: number,
    featureKey: string,
  ): Observable<{ success: true }> {
    this.hj.event("account_feature_activated");
    return this.http.post<{ success: true }>(
      `${this.endpoint}/settings/account/features/${featureKey}`,
      null,
      { params: new HttpParams({ fromObject: { account_id: accountID } }) },
    );
  }

  cancelAccountFeature(
    accountID: number,
    featureKey: string,
    reason: string,
  ): Observable<{ success: true }> {
    this.hj.event("account_feature_cancelled");
    return this.http.delete<{ success: true }>(
      `${this.endpoint}/settings/account/features/${featureKey}`,
      {
        params: new HttpParams({
          fromObject: { account_id: accountID, reason: reason },
        }),
      },
    );
  }

  activateSubscriptionPlan(key: string) {
    return this.http.post<{ success: true }>(
      `${this.endpoint}/settings/subscription/plans`,
      {
        plan: key,
      },
    );
  }

  getSubscriptionInfo() {
    return this.http.get<SubscriptionInfo>(
      `${this.endpoint}/settings/subscription`,
    );
  }

  cancelSubscription() {
    return this.http.delete<{ success: boolean }>(
      `${this.endpoint}/settings/subscription`,
    );
  }

  getSubscriptionPlans() {
    return this.http.get<CompanyPlanState[]>(
      `${this.endpoint}/settings/subscription/plans`,
    );
  }

  changeSubscription(plan_key: string) {
    return this.http.post<{ success: boolean }>(
      `${this.endpoint}/settings/subscription/active`,
      { plan: plan_key },
    );
  }
}

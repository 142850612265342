export const environment = {
  production: true,
  endpoint: "https://app.confect.io",
  APIv1Endpoint: "https://api.confect.io/v1",
  APIv2Endpoint: "https://api.confect.io",
  SocketEndpoint: "https://socket.confect.io",
  PredictiveEndpoint: "https://predictive-analytics.confect.io",
  SocketDebug: false,
  stripePublishableKey: "pk_live_7f13f7BZodH7wYQIXxnrkDcG",
  debugSpec: true,
  hotjar: 2074086,
  serviceWorker: true,
};

import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

import {
  AccountAccessInfo,
  CompanyAccessInfo,
} from "@core/api-models/users.models";

import { Subject } from "rxjs";

export interface AccessSelection {
  company: CompanyAccessInfo;
  account?: AccountAccessInfo;
}

@Injectable({
  providedIn: "root",
})
export class AccessService {
  available: CompanyAccessInfo[];

  hasOneOption = true;

  current: AccessSelection;

  private accessChanged = new Subject<AccessSelection>();
  accessChanged$ = this.accessChanged.asObservable();

  constructor(private router: Router) {
    localStorage.removeItem("currentAccess");
    const currentLS = sessionStorage.getItem("currentAccess");
    if (currentLS) {
      this.current = JSON.parse(currentLS);
      this.updateSleeknoteInfo();
      this.accessChanged.next(this.current);
    }
  }

  updateSleeknoteInfo() {
    const company = this.current?.company;

    if (!company) {
      return;
    }

    if (!company.info) {
      return;
    }

    // Add rest of Sleeknote Data
    window["sleeknoteSiteData"].push({ company_id: company.id });
    for (const [key, entry] of Object.entries(company.info)) {
      window["sleeknoteSiteData"].push({ ["company_" + key]: entry });
    }
  }

  unselectAccess(redirect = false) {
    this.current = null;
    sessionStorage.removeItem("currentAccess");
    this.accessChanged.next(this.current);

    if (redirect) {
      this.router.navigate(["/"]);
    }
  }

  updateAvailable(available: CompanyAccessInfo[], allowRedirect = true) {
    this.available = available;

    this.hasOneOption = false;
    if (this.available.length === 1) {
      const c = this.available[0];
      if (c.accounts.length <= 1) {
        this.hasOneOption = true;
      }
    }

    // Ensure current is valid, otherwise we give up
    if (this.validateCurrentAccess(allowRedirect)) {
      this.accessChanged.next(this.current);
    }
  }

  // Validate if user has this kind of access, otherwise redirect to correct location
  private validateAccessSelection(selection: AccessSelection) {
    if (!selection) {
      this.unselectAccess();
      return false;
    }
    // Make sure current is valid, otherwise throw to access screen
    // unless only one option
    const currentCompany = this.available.find(
      (c) => c.id === selection.company.id,
    );
    if (!currentCompany) {
      this.unselectAccess();
      return false;
    }

    let currentAccount = null;

    if (selection.account) {
      currentAccount = currentCompany.accounts.find(
        (a) => a.id === selection.account.id,
      );
      if (!currentAccount) {
        this.unselectAccess();
        return false;
      }
    }

    // Access is valid, leave it and update stored version
    this.current = {
      company: currentCompany,
      account: currentAccount,
    };
    sessionStorage.setItem("currentAccess", JSON.stringify(this.current));
    return true;
  }

  validateCurrentAccess(allowRedirect = true) {
    if (!this.current) {
      // If only one company+account available we select that
      if (this.hasOneOption) {
        const c = this.available[0];
        this.switchAccess(c, c.accounts[0], allowRedirect);
      }
    }

    return this.validateAccessSelection(this.current);
  }

  switchAccess(
    newCompany: CompanyAccessInfo,
    newAccount: AccountAccessInfo,
    allowRedirect = true,
  ) {
    const selection: AccessSelection = {
      company: newCompany,
      account: newAccount,
    };
    if (this.validateAccessSelection(selection)) {
      this.current = selection;
      this.updateSleeknoteInfo();
      this.accessChanged.next(this.current);
      sessionStorage.setItem("currentAccess", JSON.stringify(this.current));
      if (allowRedirect) {
        this.router.navigate(["/"]);
      }
    }
    // Forbidden
    return false;
  }

  switchAccessByIds(
    companyID: number,
    accountID: number,
    allowRedirect = true,
  ) {
    const c = this.available.find((a) => a.id === companyID);

    if (!c) {
      return false;
    }

    const a = c.accounts.find((a) => a.id === accountID);

    if (!a) {
      return false;
    }

    return this.switchAccess(c, a, allowRedirect);
  }

  hasCompanyPermission(permission: string): boolean {
    return this.current?.company.permissions[permission] === true;
  }

  hasPermission(permission: string): boolean {
    return this.current?.account.permissions[permission] === true;
  }

  hasFeature(feature: string): boolean {
    return this.current?.account.features[feature] != null;
  }

  // Whether account has any features at all
  hasNoFeatures(): boolean {
    const f = this.current?.account?.features ?? {};
    return Object.keys(f).length === 0;
  }

  hasIntegratedFacebook() {
    const iss = this.current.account?.integration_issues || {};
    return "facebook_business" in iss;
  }
}
